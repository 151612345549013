<template>
  <div>
    <h1>서울 사립초 지도</h1>
    <l-map
      style="height: 600px; width: 100%;"
      :zoom="zoom"
      :min-zoom="10"
      :center="[37.5509603, 126.979409]"
    >
      <l-tile-layer :url="tileUrl"/>
      <l-geo-json
        v-if="geojsonData"
        :geojson="geojsonData"
        :options-style="styleFeature"
        @click="onFeatureClick"
      />

      <l-marker
        v-for="(school, index) in schools"
        :key="index"
        :lat-lng="[school.lat, school.lng]"
        :name="school.name"
        @click="onMarkerClick"
      >
        <l-tooltip v-if="school.info">{{ school.info }}</l-tooltip>
        <l-icon class-name="leaflet-div-icon" :icon-size="[school.name.length > 4 ? 55 : 35, 20]" :icon-url="iconUrl">
          {{ school.name }}
        </l-icon>
      </l-marker>
    </l-map>

    <h2>서울 시내 사립초등학교 목록</h2>
    <table>
      <thead>
      <tr>
        <th>학교 이름</th>
        <th>입학 접수일</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(school, index) in schools" :key="index">
        <td>{{ school.name }}</td>
        <td>{{ school.info || 'N/A' }}</td>
      </tr>
      </tbody>
    </table>

    <h2>함께 참여해주세요</h2>
    <p>
      이 프로젝트는 <a
      href="https://docs.google.com/spreadsheets/d/1GnJvIj7mPMHrBGS7q74GWSfSuFKZsFygB2mPQT5-ljw/edit?gid=0#gid=0"
      target="_blank">서울시 사립초 목록</a>을 기반으로 만들어졌습니다.<br>
      함께 데이터를 채워주세요.
    </p>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import {
  LIcon,
  LGeoJson,
  LMap,
  LMarker,
  LTileLayer,
  LTooltip,
} from '@vue-leaflet/vue-leaflet';
import axios from 'axios';
import {feature} from 'topojson-client';

const schools = ref([
  {name: '경희초', lat: 37.5948937, lng: 127.0491562, info: '2023.11.10 (작년) 입학 접수 시작'},
  {name: '계성초', lat: 37.5046538, lng: 126.9968305},
  {name: '금성초', lat: 37.61506930000001, lng: 127.0900306},
  {name: '경기초', lat: 37.56513959999999, lng: 126.962749},
  {name: '경복초', lat: 37.54946849999999, lng: 127.0869763},
  {name: '광운초', lat: 37.6187242, lng: 127.0548494},
  {name: '서울교대부속초', lat: 37.4909058, lng: 127.015218, info: '2024.10.28 입학 접수 시작'},
  {name: '동광초', lat: 37.4519922, lng: 126.9166732},
  {name: '동산초', lat: 37.5558424, lng: 127.0168549},
  {name: '동북초', lat: 37.6552193, lng: 127.026213},
  {name: '리라초', lat: 37.557647, lng: 126.987913},
  {name: '매원초', lat: 37.5979429, lng: 127.0190943},
  {name: '명지초', lat: 37.5835561, lng: 126.9280461},
  {name: '상명초', lat: 37.6484702, lng: 127.0675904},
  {name: '서울삼육초', lat: 37.5891444, lng: 127.0478232},
  {name: '서울사대부초', lat: 37.5769772, lng: 127.002845, info: '2024.11.04 입학 접수 시작'},
  {name: '상명부속초', lat: 37.6008592, lng: 126.9562296},
  {name: '선일초', lat: 37.6197216, lng: 126.9132521, info: '2024.11.08 입학 접수 시작'},
  {name: '신광초', lat: 37.5426765, lng: 126.9701376},
  {name: '성동초', lat: 37.5328187, lng: 127.0895152},
  {name: '성신초', lat: 37.5938934, lng: 127.0232699, info: '2024.11.18 입학 접수 시작'},
  {name: '숭의초', lat: 37.55717500000001, lng: 126.9872189},
  {name: '세종초', lat: 37.5528547, lng: 127.0729844},
  {name: '예일초', lat: 37.61034719999999, lng: 126.9143885},
  {name: '우촌초', lat: 37.5956152, lng: 127.0114099},
  {name: '유석초', lat: 37.5589347, lng: 126.8505337},
  {name: '영훈초', lat: 37.61293380000001, lng: 127.0278119},
  {name: '이대부속초', lat: 37.56117319999999, lng: 126.9428198},
  {name: '은석초', lat: 37.5785691, lng: 127.0757326},
  {name: '중앙대부속초', lat: 37.5057919, lng: 126.9601952, info: '2024.11.08 입학 접수 시작'},
  {name: '추계초', lat: 37.5634792, lng: 126.9534442},
  {name: '충암초', lat: 37.58504569999999, lng: 126.9217386, info: '2024.11.08 입학 접수 시작'},
  {name: '청원초', lat: 37.66527250000001, lng: 127.0613263, info: '2024.10.26 입학 접수 시작'},
  {name: '태강삼육초', lat: 37.6418084, lng: 127.1104755, info: '2024.11.08 입학 접수 시작'},
  {name: '한신초', lat: 37.65300939999999, lng: 127.0325485, info: '2024.11.08 입학 접수 시작'},
  {name: '한양초', lat: 37.5575372, lng: 127.0503794},
  {name: '화랑초', lat: 37.6277808, lng: 127.0939571},
  {name: '홍대부속초', lat: 37.559505, lng: 126.9131773},
]);

const tileUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const geojsonData = ref(null);
const zoom = ref(11);

const iconUrl = computed(() => '/school.png');

onMounted(() => {

  axios
    .get('/seoul_municipalities_topo_simple.json')
    .then((response) => {
      geojsonData.value = feature(
        response.data,
        response.data.objects.seoul_municipalities_geo
      );
    })
    .catch((error) => {
      console.error('Error loading TopoJSON data:', error);
    })
    .finally(() => {
      console.log('Data loaded');
    });
});

const styleFeature = (feature) => {
  return {
    color: '#ffff00',
    weight: 1,
    fillColor: '#2abf4a',
    fillOpacity: 0.3,
  };
};

const onMarkerClick = (event) => {
  const latLng = event.latlng;
  const markerOptions = event.target.options;
  console.log(`Marker clicked at coordinates: ${latLng.lat}, ${latLng.lng}`);
  console.log('Marker options:', markerOptions);
  console.log('Marker iconName:', event.target.options.iconName);
};

const onFeatureClick = (event) => {
  console.log(event);
  const properties = event.layer.feature.properties;
  console.log(`${properties.name}을(를) 클릭하셨습니다.`);
};
</script>

<style lang="scss" scoped>
@import '../stylesheets/common.scss';

$primary-color: #2c3e50;
$container-width: 600px;

#schools_map {
  .leaflet-div-icon {
    background: steelblue;
    color: rgba(0, 0, 255, 0.5);
    border-radius: 50%;
    font-weight: bold;
    font-size: large;
    text-align: center;
    line-height: 21px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    border: 1px solid #ddd;

    th, td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    th {
      background-color: #f2f2f2;
      text-align: left;
    }
  }
}
</style>